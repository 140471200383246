<template>
  <div class="confirmation-dialog-content dialog-content">
    <div class="dialog-content__header">
      <span
        v-if="htmlSafe"
        v-html="message"
      ></span>
      <span v-else>{{ message }}</span>
    </div>

    <div class="dialog-content__buttons grid grid--center">
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3 dialog__button">
        <button
          ref="cancelButton"
          class="btn btn--transparent btn--full dialog-content__button"
          @click="cancel"
        >
          {{ cancelText }}
        </button>
      </div>
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3">
        <button
          ref="confirmButton"
          class="btn btn--transparent btn--full dialog-content__button"
          @click="confirm"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DialogContent from '@/mixins/dialog-content';

export default {
  name: 'ConfirmationDialogContent',
  mixins: [
    DialogContent({
      message: null,
      htmlSafe: false,
      confirmText: 'OK',
      cancelText: 'Abbrechen',
    }),
  ],
  emits: ['close'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.cancelButton.focus();
    });
  },
  methods: {
    confirm() {
      this.$emit('close', true);
    },
    cancel() {
      this.$emit('close', false);
    },
  },
};
</script>
